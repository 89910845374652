import React, { FC } from 'react';
import { Breadcrumb, Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useOrganisation } from '../api/organisation';
import { useMarking } from '../api/marking';
import { EditMarkingComponent } from '../components/EditMarkingComponent';
import { GenericErrorComponent } from '../components/GenericErrorComponent';
import { ApolloError } from '@apollo/client';
import { HomeOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';

export const MarkingPage: FC = () => {
    const params = useParams<{ organisationId: string; markingId: string }>();
    const { data: organisationData, error: organisationError } = useOrganisation(params.organisationId);
    const { data: markingData, error: markingError } = useMarking(params.markingId);
    const { t } = useTranslation();

    if (organisationError || markingError) {
        return <GenericErrorComponent error={(organisationError || markingError) as ApolloError} />;
    }

    return (
        <>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined /> {t('Home')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/organisations">{t('Organisations')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations/' + organisationData?.organisation.id}>
                                {organisationData?.organisation.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations/' + organisationData?.organisation.id + '/markings'}>
                                {t('Markings')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{markingData?.marking.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {organisationData && markingData?.marking && (
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <EditMarkingComponent marking={markingData.marking}></EditMarkingComponent>
                    </Col>
                </Row>
            )}
        </>
    );
};
