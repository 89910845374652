export const GraphQLTypenames = {
    Marking: 'Marking',
};

export interface ImageReference {
    url: string;
    mimeType: string;
}

export interface SignedUrl {
    url: string;
    exp: number;
}

export interface Organisation {
    id?: string;
    name: string;
    ruleDevelopment?: string;
    ownership?: string;
    website?: string;
    markings: Marking[];
}

export interface Marking {
    id?: string;
    organisationId?: number;
    name: string;
    shortDescription?: string;
    longDescription?: string;
    gs1Code?: string;
    logo?: ImageReference;
    categories: Category[];
}

export interface Category {
    id?: string;
    markingId?: number;
    name: string;
    description?: string;
    rule?: string;
    agendasSelected: string[];
    messages: Message[];
    filters: Filter[];
    global?: boolean;
}

export interface Filter {
    id?: string;
    categoryId: number;
    gtins: string[];
}

export interface Message {
    id?: string;
    categoryId?: number;
    shortDescription: string;
    longDescription?: string;
    selected: boolean;
}

export interface InformationProvider {
    name: string;
    gln: string;
}

export interface TypeCode {
    code: string;
    name?: string;
    description?: string;
    usageInSweden?: boolean;
}

export interface Country {
    code: string;
    name?: string;
    description?: string;
}

export interface OriginInformation {
    originArea?: string;
    originDeclaration?: string;
    activityType?: TypeCode;
    activityArea?: TypeCode;
    country?: Country;
}

export interface ProductImage {
    url: string;
    mimeType: string;
}

export interface Accreditations {
    accreditationCodes: AccreditationTypeCode[];
    providedAccreditationCodes: AccreditationTypeCode[];
}

export interface HealthRelated {
    freeFromCodes: TypeCode[];
    allergenCodes: TypeCode[];
    dietCodes: TypeCode[];
    dietSubCategoryCodes: TypeCode[];
}

export interface Markings {
    healthRelated: HealthRelated;
    accreditations: Accreditations;
}

export interface AccreditationTypeCode extends TypeCode {
    info: Marking;
}

export interface ProductCategory {
    id: string;
    messages: Message[];
    rule: string;
    description: string;
    marking: Marking;
}

export interface Product {
    id: number;
    gtin: string;
    name: string;
    brandName: string;
    category: Category;
    informationProvider: InformationProvider;
    productImage: ProductImage;
    productImages: ProductImage[];
    productCategory: ProductCategory[];
    packageInformation: {
        measurement: {
            size: string;
        };
    };
    markings?: Markings;
    originInformation?: OriginInformation;
}
