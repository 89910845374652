import { gql, useQuery } from '@apollo/client';
import { Category } from '../models/models';

const CATEGORY_QUERY = gql`
    query category($id: ID!) {
        category(id: $id) {
            id
            markingId
            name
            description
            rule
            agendasSelected
            global

            messages {
                id
                categoryId
                shortDescription
                longDescription
                selected
            }
            filters {
                id
                categoryId
                gtins
            }
        }
    }
`;

export const CATEGORY_MUTATION = gql`
    mutation categorySave($category: CategoryInput!) {
        categorySave(category: $category) {
            id
            markingId
            name
            description
            rule
            global
            agendasSelected
            messages {
                id
                categoryId
                shortDescription
                longDescription
                selected
            }
            filters {
                id
                categoryId
                gtins
            }
        }
    }
`;

export const CATEGORY_DELETE_MUTATION = gql`
    mutation categoryDelete($id: ID!) {
        categoryDelete(id: $id)
    }
`;

export const useCategory = (id: string) => {
    return useQuery<{ category: Category }>(CATEGORY_QUERY, { variables: { id: id } });
};
