import React, { FC } from 'react';
import { WarningOutlined } from '@ant-design/icons/lib';
import { ApolloError } from '@apollo/client/errors';
import { Col, Row, Typography } from 'antd';

export interface GenericErrorComponentProps {
    error: ApolloError;
}

export const GenericErrorComponent: FC<GenericErrorComponentProps> = ({ error }) => {
    return (
        <div>
            <Row gutter={[20, 0]} style={{ marginTop: '50px' }} justify={'center'}>
                <Col>
                    <WarningOutlined style={{ fontSize: '250px', color: 'red' }} />
                </Col>
            </Row>
            <Row gutter={[20, 0]} justify={'center'}>
                <Col>
                    <Typography.Title level={1} style={{ color: 'red' }}>
                        {error.message}
                    </Typography.Title>
                </Col>
            </Row>
        </div>
    );
};
