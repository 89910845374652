import { gql } from '@apollo/client';

export const FILTER_MUTATION = gql`
    mutation filterSave($filter: ProductCategoryFilterInput!) {
        filterSave(filter: $filter) {
            id
            categoryId
            gtins
        }
    }
`;
