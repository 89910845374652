import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { FC } from 'react';
import { Organisation } from '../models/models';
import { useTranslation } from 'react-i18next';

export interface RegisterOrganisationModalProps {
    showModal: boolean;
    onSubmit: (organisation: Organisation) => void;
    onCancel: () => void;
}

export const RegisterOrganisationModal: FC<RegisterOrganisationModalProps> = ({ showModal, onSubmit, onCancel }) => {
    const [registerOrganisationForm] = Form.useForm();

    const { t } = useTranslation();

    return (
        <Modal
            title={t('Register organisation')}
            visible={showModal}
            okText={t('Save')}
            cancelText={t('Close')}
            maskClosable={false}
            keyboard={false}
            closable={false}
            onOk={() => registerOrganisationForm.submit()}
            onCancel={() => {
                registerOrganisationForm.resetFields();
                onCancel();
            }}
        >
            <Form
                name="organisation"
                form={registerOrganisationForm}
                onFinish={(organisation) => onSubmit(organisation)}
                layout={'vertical'}
            >
                <Form.Item
                    label={t('Organisation')}
                    name="name"
                    rules={[
                        { required: true, message: t('Organisation name required') },
                        { max: 100, message: t('Max {{number}} characters!', { number: 100 }) },
                    ]}
                >
                    <Input autoFocus={true} />
                </Form.Item>
                <Form.Item label={t('Rule development')} name="ruleDevelopment">
                    <TextArea allowClear={true} />
                </Form.Item>
                <Form.Item label={t('Ownership')} name="ownership">
                    <TextArea allowClear={true} />
                </Form.Item>
                <Form.Item label={t('Website')} name="website">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};
