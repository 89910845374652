import React, { FC, useEffect } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, message, Row, Tooltip, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Category } from '../models/models';
import { useMutation } from '@apollo/client';
import { CATEGORY_MUTATION } from '../api/category';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface EditCategoryProps {
    category: Category;
    totalCount?: number;
    gs1Code?: string;
}

export const EditCategoryComponent: FC<EditCategoryProps> = ({ category, totalCount, gs1Code }) => {
    const [editCategoryForm] = Form.useForm();
    const [saveCategory] = useMutation<{ categorySave: Category }>(CATEGORY_MUTATION);
    const { t } = useTranslation();

    useEffect(() => {
        editCategoryForm.setFieldsValue({ id: category.id });
        editCategoryForm.setFieldsValue({ markingId: category.markingId });
        editCategoryForm.setFieldsValue({ name: category.name });
        editCategoryForm.setFieldsValue({ description: category.description });
        editCategoryForm.setFieldsValue({ rule: category.rule });
        editCategoryForm.setFieldsValue({ global: category.global });
        editCategoryForm.setFieldsValue({ agendasSelected: category.agendasSelected });
    }, [category, editCategoryForm]);

    const onSubmit = (category: Category) => {
        saveCategory({
            variables: { category: category },
        })
            .then(() => {
                message.success(t('Category saved successfully'));
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    return (
        <Card title={t('Edit category')}>
            <Form
                name="category"
                onFinish={(values) => {
                    onSubmit(values);
                }}
                layout={'vertical'}
                form={editCategoryForm}
            >
                <Form.Item name="id" hidden={true} />
                <Form.Item name="markingId" hidden={true} />
                <Form.Item
                    label={t('Name')}
                    name="name"
                    rules={[{ required: true, message: t('Please input your category name!') }]}
                >
                    <Input autoFocus={true} />
                </Form.Item>
                <Form.Item
                    label={
                        <>
                            {t('Global')}
                            <Tooltip
                                title={t('Apply this category to all products with the GS1-code {{gs1Code}}', {
                                    gs1Code,
                                })}
                            >
                                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                            </Tooltip>
                        </>
                    }
                    valuePropName="checked"
                    name="global"
                >
                    <Checkbox>
                        {t('Global category')}
                        <Typography.Text type="secondary">
                            <br></br>
                            {category.global && t('Products in GS1 ({{totalCount}})', { totalCount })}
                        </Typography.Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    label={t('Why is the marking important?')}
                    name="description"
                    rules={[{ max: 500, message: t('Max {{number}} characters!', { number: 500 }) }]}
                >
                    <TextArea allowClear={true} rows={6} />
                </Form.Item>

                <Form.Item
                    label={t('Brief description of rules')}
                    name="rule"
                    rules={[{ max: 750, message: t('Max {{number}} characters!', { number: 750 }) }]}
                >
                    <TextArea allowClear={true} rows={6} />
                </Form.Item>

                <Form.Item name="agendasSelected" label={t('Sustainability agendas')}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox value="BIODIVERSITY_AND_ECOSYSTEMS">
                                    {t('Biodiversity and ecosystems')}
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="CLIMATE_AND_AIR">{t('Climate and air')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="SOIL_FERTILITY_AND_EROSION">
                                    {t('Soil fertility and erosion')}
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="WATER">{t('Water')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="CHEMICALS_AND_PESTICIDES">{t('Chemicals and pesticides')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="EUTROPHICATION">{t('Eutrophication')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="ANIMAL_WELFARE">{t('Animal welfare')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="WORKING_CONDITIONS">{t('Working conditions')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="LOCAL_POPULATIONS">{t('Local populations')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="LEGALTY_TRACEABILITY">{t('Legalty traceability')}</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <Row gutter={[20, 20]} justify={'end'}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Button htmlType="submit" type={'primary'} block={true}>
                            {t('Save')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
