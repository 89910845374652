import { Breadcrumb, Button, Card, Col, Form, Input, message, Row, Select, Space } from 'antd';
import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { buildPath } from '../../utils/build-path';
import { ROUTES } from '../../App';
import { useOrganisation } from '../../api/organisation';
import { COUNT_PRODUCT_IN_MARKING, useMarking } from '../../api/marking';
import { GenericErrorComponent } from '../../components/GenericErrorComponent';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useCategory } from '../../api/category';
import { EditCategoryComponent } from '../../components/EditCategoryComponent';
import { HomeOutlined } from '@ant-design/icons';
import { EditMessagesComponent } from '../../components/EditMessagesComponent';
import { Filter } from '../../models/models';
import { FILTER_MUTATION } from '../../api/filter';
import { ProductsTable } from '../../components/ProductsTableComponent';
import { useTranslation } from 'react-i18next';
import { GTINsUploader } from './GTINsUploader';
import { CsvDownload } from '../../components/CsvDownload';
import { isStringGuard } from '../../utils/typescript';

const CategoryPage: FC = () => {
    const params = useParams<{ organisationId: string; markingId: string; categoryId: string }>();

    const { data: organisationData, error: organisationError } = useOrganisation(params.organisationId);
    const { data: markingData, error: markingError } = useMarking(params.markingId);
    const { data: categoryData, error: categoryError } = useCategory(params.categoryId);
    const { data: productCount } = useQuery<{ count: { total: number } }, any>(COUNT_PRODUCT_IN_MARKING, {
        skip: !markingData?.marking.id,
        variables: {
            id: markingData?.marking.gs1Code,
        },
    });

    const [saveFilter] = useMutation<{ filterSave: Filter }>(FILTER_MUTATION);
    const [productsForm] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if (categoryData?.category && categoryData?.category.filters.length > 0) {
            const gtins = categoryData.category.filters[0].gtins;
            productsForm.setFieldsValue({ filterId: categoryData.category.filters[0].id });
            productsForm.setFieldsValue({ gtins });
            setGTINs(gtins);
        }
    }, [categoryData, productsForm]);

    const onSubmit = useCallback(
        (filter: Filter) => {
            saveFilter({
                variables: {
                    filter: filter,
                },
                refetchQueries: ['category'],
            })
                .then(() => {
                    message.success(t(`Gtin's saved successfully`));
                })
                .catch((err) => {
                    message.error(err.message);
                });
        },
        [saveFilter, t]
    );
    const onGTINsUploaded = useCallback(
        (gtins: string[]) => {
            productsForm.setFieldsValue({
                gtins,
            });
            setGTINs(gtins);
        },
        [productsForm]
    );

    const [GTINs, setGTINs] = useState<string[]>([]);

    if (organisationError || markingError || categoryError) {
        return <GenericErrorComponent error={(organisationError || markingError || categoryError) as ApolloError} />;
    }

    return (
        <>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined /> {t('Home')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={ROUTES.organisations.path}>{t('Organisations')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={buildPath(ROUTES.organisation.path, {
                                    organisationId: params.organisationId,
                                })}
                            >
                                {organisationData?.organisation.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={buildPath(ROUTES.markings.path, {
                                    organisationId: params.organisationId,
                                    markingId: params.markingId,
                                })}
                            >
                                {t('Markings')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={buildPath(ROUTES.marking.path, {
                                    organisationId: params.organisationId,
                                    markingId: params.markingId,
                                    categoryId: params.categoryId,
                                })}
                            >
                                {markingData?.marking.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={buildPath(ROUTES.categories.path, {
                                    organisationId: params.organisationId,
                                    markingId: params.markingId,
                                    categoryId: params.categoryId,
                                })}
                            >
                                {t('Categories')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{categoryData?.category.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {categoryData?.category && (
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <EditCategoryComponent
                            gs1Code={markingData?.marking.gs1Code}
                            totalCount={productCount?.count.total}
                            category={categoryData.category}
                        />
                    </Col>
                </Row>
            )}

            {categoryData?.category && (
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <EditMessagesComponent
                            messages={categoryData.category.messages}
                            categoryId={Number(categoryData.category.id)}
                        />
                    </Col>
                </Row>
            )}

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Card title={t('Associated products')}>
                        <Form
                            name="products"
                            onFinish={(values) => {
                                const filter = {
                                    id: values.filterId,
                                    categoryId: Number(categoryData?.category.id),
                                    gtins: values.gtins
                                        .map((gtin: string) => gtin.trim())
                                        .map((gtin: string) => gtin.padStart(14, '0')),
                                };
                                onSubmit(filter);
                            }}
                            layout={'vertical'}
                            form={productsForm}
                        >
                            <Form.Item name={'filterId'} hidden={true}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="gtins" label="GTINs">
                                <Select
                                    maxTagCount={20}
                                    mode="tags"
                                    tokenSeparators={[',', ' ', '\n', ';']}
                                    allowClear={true}
                                    placeholder={t('Copy and paste a list of gtins')}
                                    onChange={(tags) => {
                                        if (!Array.isArray(tags)) {
                                            console.warn('not an array');
                                            return;
                                        }
                                        const definitelyNotLabeledTags = tags as ReactText[];
                                        const strings = definitelyNotLabeledTags
                                            .filter(isStringGuard)
                                            .map((gtin) => gtin.trim())
                                            .map((gtin: string) => gtin.padStart(14, '0'));
                                        setGTINs(strings);
                                    }}
                                />
                            </Form.Item>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Space>
                                        <CsvDownload GTINs={GTINs} fileName={categoryData?.category.name || 'GTINs'} />
                                        <GTINsUploader onGTINsUploaded={onGTINsUploaded} />
                                        <Button htmlType="submit" type={'primary'}>
                                            {t('Save')}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>

                        {markingData?.marking && categoryData?.category && categoryData?.category.filters.length > 0 && (
                            <Row gutter={[20, 20]}>
                                <Col span={24}>
                                    <ProductsTable
                                        gtins={categoryData.category.filters[0].gtins}
                                        marking={markingData?.marking}
                                        removeFromList={(product) => {
                                            const gtins = [...categoryData.category.filters[0].gtins];
                                            const indexToRemove = gtins.indexOf(product.gtin);
                                            gtins.splice(indexToRemove, 1);

                                            const filter: Filter = {
                                                id: productsForm.getFieldValue('filterId'),
                                                categoryId: Number(categoryData.category.id),
                                                gtins: gtins,
                                            };

                                            onSubmit(filter);
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default CategoryPage;
