import './App.scss';
import React, { FC, useEffect, useState } from 'react';
import { Link, Redirect, Route, Router, Switch, withRouter } from 'react-router-dom';
import { Button, Col, Layout, message, Row, Typography } from 'antd';
import { browserHistory } from './history';
import MainPage from './pages/MainPage';
import { env } from './utils/env';
import { OrganisationPage } from './pages/OrganisationPage';
import { MarkingPage } from './pages/MarkingPage';
import CategoryPage from './pages/CategoryPage';
import { MarkingsPage } from './pages/MarkingsPage';
import { OrganisationsPage } from './pages/OrganisationsPage';
import { CategoriesPage } from './pages/CategoriesPage';
import SignInPage from './pages/SignInPage';
import { QueryParamProvider } from 'use-query-params';
import { Auth } from '@aws-amplify/auth';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import { ProductPage } from './pages/ProductPage';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons/lib';

const { Header, Content, Footer } = Layout;

export const ROUTES = {
    organisations: {
        path: '/organisations',
        component: OrganisationsPage,
        exact: true,
    },
    organisation: {
        path: '/organisations/:organisationId',
        component: OrganisationPage,
        exact: true,
    },
    markings: {
        path: '/organisations/:organisationId/markings',
        component: MarkingsPage,
        exact: true,
    },
    marking: {
        path: '/organisations/:organisationId/markings/:markingId',
        component: MarkingPage,
        exact: true,
    },
    categories: {
        path: '/organisations/:organisationId/markings/:markingId/categories',
        component: CategoriesPage,
        exact: true,
    },
    category: {
        path: '/organisations/:organisationId/markings/:markingId/categories/:categoryId',
        component: CategoryPage,
        exact: true,
    },
    signIn: {
        path: '/signin',
        component: SignInPage,
        exact: true,
    },
    product: {
        path: '/products/:gtin',
        component: ProductPage,
    },
};

const MainHeader = withRouter(({ history, location }) => {
    const [userInfo, setUserInfo] = useState<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (location.pathname !== ROUTES.signIn.path) {
            // checking on every route change, to be certain that the userInfo we have is still correct
            Auth.currentUserInfo().then((info) => {
                setUserInfo(info);
                if (!info) {
                    history.push(ROUTES.signIn.path);
                }
            });
        }
    }, [location, history]);

    return (
        <Header>
            <Row gutter={[20, 0]}>
                <Col span={12}>
                    <Link to="/">
                        <img
                            src={process.env.PUBLIC_URL + '/FF_logo.png'}
                            style={{ maxHeight: '24px' }}
                            alt="Foodfacts admin"
                        />
                    </Link>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {userInfo && (
                        <Button
                            icon={<LogoutOutlined />}
                            type={'ghost'}
                            style={{ color: 'white' }}
                            onClick={() =>
                                Auth.signOut().then(() => {
                                    setUserInfo(null);
                                    history.push(ROUTES.signIn.path);
                                })
                            }
                        >
                            {t('Logout {{username}}', { username: userInfo?.username })}
                        </Button>
                    )}
                </Col>
            </Row>
        </Header>
    );
});

const App: FC = () => {
    const { t, i18n } = useTranslation();

    return (
        <ApolloProvider client={client}>
            <Router history={browserHistory}>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <Layout style={{ minHeight: '100vh' }}>
                        <MainHeader />
                        <Layout>
                            <Content>
                                <div className="content">
                                    <Switch>
                                        <Route {...ROUTES.organisations} />
                                        <Route {...ROUTES.organisation} />
                                        <Route {...ROUTES.markings} />
                                        <Route {...ROUTES.marking} />
                                        <Route {...ROUTES.categories} />
                                        <Route {...ROUTES.category} />
                                        <Route {...ROUTES.signIn} />
                                        <Route {...ROUTES.product} />
                                        <Route key="/" path="/" component={MainPage} />
                                        <Redirect to="/organisations" />
                                    </Switch>
                                </div>
                            </Content>
                        </Layout>
                        <Footer>
                            <Row gutter={[20, 0]} align={'middle'} justify="center">
                                <Col lg={16} md={16} sm={24} xs={24}>
                                    <Typography.Text>
                                        {`stage ${env.REACT_APP_STAGE}, version ${env.REACT_APP_BUILD_VERSION}`}
                                    </Typography.Text>
                                </Col>
                                <Col lg={4} sm={24} xs={24}>
                                    <Button
                                        block={true}
                                        type={'ghost'}
                                        size={'large'}
                                        icon={
                                            <img
                                                src={process.env.PUBLIC_URL + '/2560px-Flag_of_Sweden.png'}
                                                alt={'Sweden'}
                                                style={{ height: '20px', paddingRight: '10px' }}
                                            />
                                        }
                                        onClick={async (event) => {
                                            event.preventDefault();
                                            await i18n.changeLanguage('sv');
                                            message.info(t('Language changed'));
                                        }}
                                    >
                                        {t('Swedish')}
                                    </Button>
                                </Col>
                                <Col lg={4} sm={24} xs={24}>
                                    <Button
                                        block={true}
                                        size={'large'}
                                        type={'ghost'}
                                        icon={
                                            <img
                                                src={process.env.PUBLIC_URL + '/2560px-Flag_of_the_United_Kingdom.png'}
                                                alt={'United kingdom'}
                                                style={{ height: '20px', paddingRight: '10px' }}
                                            />
                                        }
                                        onClick={async (event) => {
                                            event.preventDefault();
                                            await i18n.changeLanguage('en');
                                            message.info(t('Language changed'));
                                        }}
                                    >
                                        {t('English')}
                                    </Button>
                                </Col>
                            </Row>
                        </Footer>
                    </Layout>
                </QueryParamProvider>
            </Router>
        </ApolloProvider>
    );
};

export default App;
