import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { FC } from 'react';
import { Marking } from '../models/models';
import { useTranslation } from 'react-i18next';

export interface RegisterMarkingModalProps {
    showModal: boolean;
    onSubmit: (marking: Marking) => void;
    onCancel: () => void;
}

export const RegisterMarkingModal: FC<RegisterMarkingModalProps> = ({ showModal, onSubmit, onCancel }) => {
    const [registerMarkingForm] = Form.useForm();

    const { t } = useTranslation();

    return (
        <Modal
            title={t('Register marking')}
            visible={showModal}
            okText={t('Save')}
            cancelText={t('Close')}
            maskClosable={false}
            keyboard={false}
            closable={false}
            onOk={() => registerMarkingForm.submit()}
            onCancel={() => {
                registerMarkingForm.resetFields();
                onCancel();
            }}
        >
            <Form
                name="marking"
                form={registerMarkingForm}
                onFinish={(marking) => onSubmit(marking)}
                layout={'vertical'}
            >
                <Form.Item
                    label={t('Marking')}
                    name="name"
                    rules={[{ required: true, message: t('Please input your marking!') }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t('Short description')}
                    name="shortDescription"
                    rules={[{ max: 80, message: t('Max {{number}} characters!', { number: 80 }) }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label={t('Long description')} name="longDescription">
                    <TextArea allowClear={true} rows={5} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
