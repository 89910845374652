import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Form, Input, message, Popconfirm, Row, Switch, Tooltip } from 'antd';
import { Message } from '../models/models';
import { useTranslation } from 'react-i18next';
import { DeleteTwoTone, InfoCircleOutlined, PlusSquareTwoTone } from '@ant-design/icons/lib';
import TextArea from 'antd/es/input/TextArea';
import { StoreValue, useMutation } from '@apollo/client';
import { MESSAGES_DELETED_MUTATION, MESSAGES_MUTATION } from '../api/message';
import { FormListFieldData } from 'antd/es/form/FormList';

const { Panel } = Collapse;

export interface EditMessagesComponentProps {
    messages: Message[];
    categoryId: number;
}

export const EditMessagesComponent: FC<EditMessagesComponentProps> = ({ messages, categoryId }) => {
    const [editMessagesForm] = Form.useForm();
    const [messagesSave] = useMutation<{ messagesSelected: Message[] }>(MESSAGES_MUTATION);
    const [messagesDelete] = useMutation<{ messagesSelected: void }>(MESSAGES_DELETED_MUTATION);
    const { t } = useTranslation();
    const [activePanel, setActivePanel] = useState<string | string[] | undefined>(undefined);

    const onSubmit = (formValues: any) => {
        const messages: Message[] = formValues.messages.map(
            ({ id, categoryId, shortDescription, longDescription, selected }: Message) => ({
                id,
                categoryId,
                shortDescription,
                longDescription,
                selected,
            })
        );
        messagesSave({ variables: { messages }, refetchQueries: ['category', 'uniqueProductsByGTINs'] })
            .then(() => {
                message.success(t('Product values saved successfully'));
                setActivePanel(undefined);
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    useEffect(() => {
        editMessagesForm.setFieldsValue({
            messages:
                messages.length === 0
                    ? [
                          {
                              id: null,
                              categoryId: categoryId,
                              shortDescription: null,
                              longDescription: null,
                              selected: true,
                          },
                      ]
                    : messages,
        });

        if (messages.length === 0) {
            setActivePanel('0');
        }
    }, [messages, editMessagesForm, categoryId]);

    function renderActions(
        index: number,
        remove: (index: number | number[]) => void,
        arr: FormListFieldData[],
        add: (defaultValue?: StoreValue, insertIndex?: number) => void
    ) {
        return (
            <>
                {index === arr.length - 1 && (
                    <Tooltip title={t('Add a new product value')}>
                        <Button
                            shape={'round'}
                            type={'dashed'}
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                add({
                                    id: null,
                                    categoryId: categoryId,
                                    shortDescription: null,
                                    longDescription: null,
                                    selected: true,
                                });
                                setActivePanel(arr.length.toString());
                            }}
                            icon={<PlusSquareTwoTone twoToneColor={'#1890ff'} />}
                            style={{ marginRight: 10 }}
                        />
                    </Tooltip>
                )}
                <Popconfirm
                    placement={'left'}
                    title={t('Are you sure to delete this product value?')}
                    onConfirm={(event) => {
                        event?.stopPropagation();
                        const msg: Message = editMessagesForm.getFieldValue('messages')[index];
                        remove(index);

                        if (msg.id) {
                            messagesDelete({
                                variables: {
                                    ids: [Number(msg.id)],
                                    refetchQueries: ['category', 'uniqueProductsByGTINs'],
                                },
                            })
                                .then(() => {
                                    message.success(t('Product value successfully removed'));
                                })
                                .catch((err) => {
                                    message.error(err.message);
                                });
                        }
                    }}
                    onCancel={(event) => {
                        event?.stopPropagation();
                    }}
                    okText={t('Yes')}
                    cancelText={t('No')}
                >
                    <Button
                        danger
                        type={'dashed'}
                        shape={'round'}
                        icon={<DeleteTwoTone twoToneColor={'red'} />}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                    />
                </Popconfirm>
            </>
        );
    }

    return (
        <Card title={t('Edit product values')}>
            <Form
                name="messages"
                onFinish={(values) => {
                    onSubmit(values);
                }}
                layout={'vertical'}
                form={editMessagesForm}
            >
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <Form.List name="messages">
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Collapse
                                        bordered={false}
                                        accordion={true}
                                        activeKey={activePanel}
                                        onChange={(n) => {
                                            setActivePanel(n);
                                        }}
                                        className={'product-values-panels'}
                                    >
                                        {fields.map((field, index, arr) => (
                                            <Panel
                                                header={
                                                    editMessagesForm.getFieldValue('messages')[index].shortDescription
                                                }
                                                key={field.key}
                                                extra={renderActions(index, remove, arr, add)}
                                            >
                                                <Row gutter={[20, 0]}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[field.name, 'shortDescription']}
                                                            label={t('Product value')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('{{field}} is required', {
                                                                        field: t('Product value'),
                                                                    }),
                                                                },
                                                                {
                                                                    max: 45,
                                                                    message: t('Max {{number}} characters!', {
                                                                        number: 45,
                                                                    }),
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder={t('Product value')} autoFocus={true} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[field.name, 'longDescription']}
                                                            label={t('Text')}
                                                            rules={[
                                                                {
                                                                    max: 400,
                                                                    message: t('Max {{number}} characters!', {
                                                                        number: 400,
                                                                    }),
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea rows={6} allowClear={true} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[field.name, 'selected']}
                                                            label={t('Activated')}
                                                            valuePropName="checked"
                                                            tooltip={{
                                                                title: t('Visible in product views'),
                                                                icon: <InfoCircleOutlined />,
                                                            }}
                                                        >
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        ))}
                                    </Collapse>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>

                <Row gutter={[20, 20]} justify={'end'}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Button htmlType="submit" type={'primary'} block={true}>
                            {t('Save')}
                        </Button>
                    </Col>
                </Row>
            </Form>

            {/*<DeleteMessagesModal*/}
            {/*    showModal={showDeleteMessageModal}*/}
            {/*    messages={messages}*/}
            {/*    onCancel={() => setShowDeleteMessageModal(false)}*/}
            {/*    onSubmit={(ids) => {*/}
            {/*        messagesDelete({*/}
            {/*            variables: {*/}
            {/*                ids: ids.map((id: string) => Number(id)),*/}
            {/*            },*/}
            {/*            refetchQueries: ['category'],*/}
            {/*        })*/}
            {/*            .then(() => {*/}
            {/*                message.success(t('Product value successfully removed'));*/}
            {/*            })*/}
            {/*            .catch((err) => {*/}
            {/*                message.error(err.message);*/}
            {/*            })*/}
            {/*            .finally(() => {*/}
            {/*                setShowDeleteMessageModal(false);*/}
            {/*            });*/}
            {/*    }}*/}
            {/*/>*/}
        </Card>
    );
};
