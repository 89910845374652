import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Card, Modal, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { GTINsUploaderSteps } from './types';
import { UploadFileStep } from './UploadFileStep';
import { RcFile } from 'antd/lib/upload/interface';
import { ParseFileStep } from './ParseFileStep';
import { SelectGTINsColumnStep } from './SelectGTINsColumnStep';
import { CsvDataType } from '../../../components/CsvPreview';

const { Step } = Steps;

interface IGTINSUploaderProps {
    onGTINsUploaded: (gtins: string[]) => void;
}

export const GTINsUploader: FC<IGTINSUploaderProps> = (props) => {
    const { onGTINsUploaded } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { t } = useTranslation();
    const handleCancel = useCallback(() => {
        setIsModalVisible(false);
    }, []);

    const showModal = useCallback(() => {
        setIsModalVisible(true);
    }, []);

    const handleGTINsUploaded = useCallback(
        (gtins: string[]) => {
            setIsModalVisible(false);
            onGTINsUploaded(gtins);
        },
        [onGTINsUploaded]
    );
    return (
        <>
            <Button onClick={showModal}>{t('Upload GTINs from CSV')}</Button>
            <Modal
                title={t('Upload GTINs from CSV')}
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={'80%'}
                destroyOnClose={true}
            >
                <UploaderModalContent onGTINsUploaded={handleGTINsUploaded} />
            </Modal>
        </>
    );
};

export interface IUploaderModalContentProps {
    onGTINsUploaded: (gtins: string[]) => void;
}
export const UploaderModalContent: FC<IUploaderModalContentProps> = (props) => {
    const { onGTINsUploaded } = props;
    const { t } = useTranslation();
    const [current, setCurrent] = useState(GTINsUploaderSteps.UploadFile);
    const [file, setFile] = useState<RcFile | null>(null);
    const [csvData, setCsvData] = useState<{ columns: { title: string; dataIndex: string }[]; data: CsvDataType[] }>({
        columns: [],
        data: [],
    });

    const handleFileLoaded = useCallback((file: RcFile) => {
        setFile(file);
        setCurrent(GTINsUploaderSteps.Parse);
    }, []);

    const handleDataParsed = useCallback(
        (data: { columns: { title: string; dataIndex: string }[]; data: CsvDataType[] }) => {
            setCsvData(data);
            setCurrent(GTINsUploaderSteps.SelectColumn);
        },
        []
    );
    return (
        <>
            <Steps current={current}>
                <Step key={GTINsUploaderSteps.UploadFile} title={t('Upload a file')} />
                <Step key={GTINsUploaderSteps.Parse} title={t('Parse')} />
                <Step key={GTINsUploaderSteps.SelectColumn} title={t('SelectColumn')} />
            </Steps>
            <HideableBox hidden={current !== GTINsUploaderSteps.UploadFile}>
                <UploadFileStep onFileLoaded={handleFileLoaded} />
            </HideableBox>
            <HideableBox hidden={current !== GTINsUploaderSteps.Parse}>
                {file && <ParseFileStep file={file} onParsed={handleDataParsed} />}
            </HideableBox>
            <HideableBox hidden={current !== GTINsUploaderSteps.SelectColumn}>
                <SelectGTINsColumnStep csvData={csvData} onSubmit={onGTINsUploaded} />
            </HideableBox>
        </>
    );
};

interface IHidableBoxProps {
    hidden: boolean;
}
const HideableBox: FC<IHidableBoxProps> = (props) => {
    const { children, hidden } = props;
    const style = useMemo(() => {
        return { display: hidden ? 'none' : 'initial' };
    }, [hidden]);
    return <Card style={style}>{children}</Card>;
};
