import React, { FC } from 'react';
import { useAllOrganisations } from '../api/organisation';
import { Breadcrumb, Card, Col, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { useHistory } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';

const MainPage: FC = () => {
    const { data, loading } = useAllOrganisations();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <HomeOutlined /> {t('Home')}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Card title={t('Markings')} loading={loading}>
                        <Row gutter={[20, 20]} justify={'center'}>
                            {data?.organisations
                                .flatMap((organisation) => organisation.markings)
                                .map((marking) => (
                                    <Col lg={8} md={12} sm={24} xs={24} key={marking.id}>
                                        <Card
                                            className="markings-card"
                                            onClick={() =>
                                                history.push(
                                                    buildPath(ROUTES.marking.path, {
                                                        organisationId: marking.organisationId + '',
                                                        markingId: marking.id as string,
                                                    })
                                                )
                                            }
                                            hoverable
                                            bordered={false}
                                            cover={
                                                <div className="markings-card-cover">
                                                    <img alt={marking.name + ' logo'} src={marking.logo?.url} />
                                                </div>
                                            }
                                        >
                                            <Meta title={marking.name} description={marking.shortDescription} />
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
export default MainPage;
