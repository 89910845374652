import { FC, useCallback } from 'react';
import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import stringify from 'csv-stringify/lib/sync';

export interface ICsvDownloadProps {
    GTINs: string[];
    fileName: string;
}

export const CsvDownload: FC<ICsvDownloadProps> = (props) => {
    const { GTINs, fileName } = props;
    const { t } = useTranslation();

    const onHandleDownload = useCallback(() => {
        const rows = rowsGen(GTINs);
        const content = stringify(rows as any, {
            columns: ['GTINs'],
            header: true,
            delimiter: ',',
        });
        fileDownload(content, `${fileName}.csv`);
    }, [GTINs, fileName]);
    return (
        <>
            <Button onClick={onHandleDownload}>{t('Download CSV')}</Button>
        </>
    );
};

function* rowsGen(gtins: string[]): Generator<Record<string, string>> {
    for (const s of gtins) {
        yield { GTINs: s };
    }
}
