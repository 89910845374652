import { gql, useQuery } from '@apollo/client';
import { Product } from '../models/models';

const PRODUCTS_QUERY = gql`
    query uniqueProductsByGTINs($gtins: [String!]!) {
        uniqueProductsByGTINs(gtins: $gtins) {
            id
            gtin
            name
            brandName
            informationProvider {
                gln
                name
            }
            productImage(size: x_small) {
                url
                mimeType
            }
            productImages(size: medium) {
                url
                mimeType
            }
            packageInformation {
                measurement {
                    size
                }
            }
            productCategory {
                id
                description
                rule
                messages {
                    id
                    categoryId
                    shortDescription
                    longDescription
                    selected
                }
                marking {
                    id
                    name
                    logo {
                        url
                    }
                }
            }
            markings {
                accreditations {
                    accreditationCodes {
                        code
                        name
                        description
                        info {
                            id
                            name
                            logo {
                                mimeType
                                url
                            }
                            categories {
                                messages {
                                    shortDescription
                                    longDescription
                                    selected
                                }
                            }
                            shortDescription
                        }
                    }
                    providedAccreditationCodes {
                        info {
                            id
                            name
                            logo {
                                mimeType
                                url
                            }
                            categories {
                                messages {
                                    shortDescription
                                    longDescription
                                    selected
                                }
                            }
                            shortDescription
                        }
                    }
                }
            }
            originInformation {
                country {
                    name
                }
                originArea
                originDeclaration
                activityType {
                    code
                    name
                }
                activityArea {
                    code
                    name
                }
            }
        }
    }
`;

const GTINS_WITH_MISSING_GS1_CODE = gql`
    query gtinsWithMissingGs1Code($gtins: [String!]!, $gs1Code: String!) {
        gtinsWithMissingGs1Code(gtins: $gtins, gs1Code: $gs1Code)
    }
`;

export const useProducts = (gtins: string[]) => {
    return useQuery<{ uniqueProductsByGTINs: Product[] }>(PRODUCTS_QUERY, { variables: { gtins: gtins } });
};

export const useGtinsWithMissingCode = (gtins: string[], gs1Code: string | undefined) => {
    return useQuery<{ gtinsWithMissingGs1Code: string[] }>(GTINS_WITH_MISSING_GS1_CODE, {
        variables: { gtins: gtins, gs1Code },
        skip: !gs1Code || gtins.length === 0,
    });
};
