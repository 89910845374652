import { gql, useQuery } from '@apollo/client';
import { Organisation } from '../models/models';

const ALL_ORGANISATIONS_QUERY = gql`
    query organisations {
        organisations {
            id
            name
            ruleDevelopment
            ownership
            website

            markings {
                id
                organisationId
                gs1Code
                name
                shortDescription
                longDescription
                logo {
                    url
                }
            }
        }
    }
`;

const ORGANISATION_QUERY = gql`
    query organisation($id: ID!) {
        organisation(id: $id) {
            id
            name
            ruleDevelopment
            ownership
            website
            markings {
                id
                organisationId
                name
                gs1Code
                shortDescription
                longDescription
                logo {
                    url
                }
                categories {
                    id
                    markingId
                    name
                }
            }
        }
    }
`;

export const ORGANISATION_MUTATION = gql`
    mutation organisationSave($organisation: OrganisationInput!) {
        organisationSave(organisation: $organisation) {
            id
            name
            ruleDevelopment
            ownership
            website
            markings {
                id
                organisationId
                name
                gs1Code
                shortDescription
                longDescription
                categories {
                    id
                    markingId
                    name
                }
            }
        }
    }
`;

export const useAllOrganisations = () => {
    return useQuery<{ organisations: Organisation[] }>(ALL_ORGANISATIONS_QUERY);
};

export const useOrganisation = (id: string) => {
    return useQuery<{ organisation: Organisation }>(ORGANISATION_QUERY, { variables: { id: id } });
};
