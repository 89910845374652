import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import { env } from './utils/env';

const httpLink = createHttpLink({
    uri: env.REACT_APP_GATEWAY_ENDPOINT + '/graphql',
});

const authLink = setContext(async (_, { headers }) => {
    const cognitoUserSession = await Auth.currentSession();
    return {
        headers: {
            ...headers,
            authorization: cognitoUserSession ? `Bearer ${cognitoUserSession.getAccessToken().getJwtToken()}` : '',
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
