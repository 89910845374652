import { gql } from '@apollo/client';

export const MESSAGE_MUTATION = gql`
    mutation messageSave($message: MessageInput!) {
        messageSave(message: $message) {
            id
            categoryId
            shortDescription
            longDescription
            selected
        }
    }
`;

export const MESSAGES_MUTATION = gql`
    mutation messagesSave($messages: [MessageInput!]!) {
        messagesSave(messages: $messages) {
            id
            categoryId
            shortDescription
            longDescription
            selected
        }
    }
`;

export const MESSAGES_SELECTED_MUTATION = gql`
    mutation messagesSelected($categoryId: Int!, $ids: [Int!]!) {
        messagesSelected(categoryId: $categoryId, ids: $ids) {
            id
            categoryId
            shortDescription
            longDescription
            selected
        }
    }
`;

export const MESSAGES_DELETED_MUTATION = gql`
    mutation messagesDelete($ids: [Int!]!) {
        messagesDelete(ids: $ids)
    }
`;
