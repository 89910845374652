import { gql, useQuery } from '@apollo/client';
import { Marking } from '../models/models';

export const MARKING_QUERY = gql`
    query marking($id: ID!) {
        marking(id: $id) {
            id
            organisationId
            name
            shortDescription
            longDescription
            gs1Code
            logo {
                url
                mimeType
            }
            categories {
                id
                markingId
                name
                description
                rule
                messages {
                    id
                    categoryId
                    selected
                    shortDescription
                }
            }
        }
    }
`;

export const MARKING_MUTATION = gql`
    mutation markingSave($marking: MarkingInput!) {
        markingSave(marking: $marking) {
            id
            organisationId
            name
            shortDescription
            longDescription
            gs1Code
            logo {
                url
                mimeType
            }
            categories {
                id
                markingId
                name
                description
                rule
                messages {
                    id
                    categoryId
                    shortDescription
                }
            }
        }
    }
`;

export const LOGO_SIGNED_URL_MUTATION = gql`
    mutation logoSignedUrl($mimeType: String!, $markingId: ID!) {
        logoSignedUrl(mimeType: $mimeType, markingId: $markingId) {
            exp
            url
        }
    }
`;

export const LOGO_UPLOADED_SUCCESS_MUTATION = gql`
    mutation logoUploadedSuccess($mimeType: String!, $markingId: ID!) {
        logoUploadedSuccess(mimeType: $mimeType, markingId: $markingId) {
            id
            logo {
                url
            }
        }
    }
`;

export const MARKING_DELETE_MUTATION = gql`
    mutation markingDelete($id: ID!) {
        markingDelete(id: $id)
    }
`;

export const COUNT_PRODUCT_IN_MARKING = gql`
    query($id: String!) {
        count: productsSearch(accreditedMarking: [$id]) {
            total
        }
    }
`;

export const useMarking = (id: string) => {
    return useQuery<{ marking: Marking }>(MARKING_QUERY, { variables: { id: id } });
};
