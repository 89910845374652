import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Col, notification, Row, Select, Table, Typography } from 'antd';
import parse from 'csv-parse/lib/sync';
import { ColumnType } from 'antd/lib/table/interface';

export interface CsvFilePreviewProps {
    onChange: (data: { columns: { title: string; dataIndex: string }[]; data: Record<string, unknown>[] }) => void;
    csvContent: string;
}

enum Delimiter {
    Comma = ',',
    Semicolon = ';',
}

type ParsedRow<UseHeaders extends boolean> = UseHeaders extends true ? Record<string, unknown> : unknown[];
export type CsvDataType = Record<string, unknown>;
interface MyColumnType extends ColumnType<Record<string, unknown>> {
    title: string;
    dataIndex: string;
}
export const CsvFilePreview: FC<CsvFilePreviewProps> = (props) => {
    const { csvContent, onChange } = props;
    const [delimiter, setDelimiter] = useState(Delimiter.Comma);
    const [useHeader, setUseHeader] = useState<boolean>(true);

    const data = useMemo((): CsvDataType[] => {
        try {
            const parsedContent = parse(csvContent, {
                delimiter,
                columns: useHeader,
            }) as ParsedRow<typeof useHeader>[];
            if (useHeader) {
                return parsedContent as ParsedRow<typeof useHeader>[];
            } else {
                const knownContent = parsedContent as ParsedRow<typeof useHeader>[];
                return knownContent.map((row) => {
                    return Object.fromEntries(row.map((cell, i) => [i, cell]));
                });
            }
        } catch (e) {
            notification.error({
                message: String(e),
            });
            return [];
        }
    }, [csvContent, delimiter, useHeader]);

    const columns = useMemo((): MyColumnType[] => {
        const firstRow = data[0] || [];
        const headers = Object.keys(firstRow);
        return headers.map((header) => ({
            title: header,
            dataIndex: header,
            ellipsis: true,
        }));
    }, [data]);
    useEffect(() => {
        onChange({ columns, data });
    }, [columns, data, onChange]);

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Typography.Text>
                        Preview the file to determine and select the GTIN column, then press ok.
                    </Typography.Text>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Select defaultValue={delimiter} onChange={setDelimiter}>
                        <Select.Option value={Delimiter.Comma}>Comma</Select.Option>
                        <Select.Option value={Delimiter.Semicolon}>Semicolon</Select.Option>
                    </Select>

                    <Button
                        type={'primary'}
                        onClick={() => setUseHeader((prevState) => !prevState)}
                        style={{ marginLeft: '20px' }}
                    >
                        Toggle header
                    </Button>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Table
                        tableLayout={'fixed'}
                        dataSource={data}
                        columns={columns}
                        rowKey={'__id'}
                        scroll={{ x: 1900 }}
                    />
                </Col>
            </Row>
        </>
    );
};
