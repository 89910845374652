import React, { FC, useCallback, useState } from 'react';
import { Button, Card, Empty, Select } from 'antd';
import { isStringGuard } from '../../../../utils/typescript';
import styles from './styles.module.scss';
import { CsvDataType } from '../../../../components/CsvPreview';
import { useTranslation } from 'react-i18next';

export interface ISelectGTINsColumnStepProps {
    csvData: { columns: { title: string; dataIndex: string }[]; data: CsvDataType[] };
    onSubmit: (GTINs: string[]) => void;
}

export const SelectGTINsColumnStep: FC<ISelectGTINsColumnStepProps> = (props) => {
    const { csvData, onSubmit } = props;
    const [GTINs, setGTINs] = useState<string[]>([]);

    const { t } = useTranslation();

    const handleGtinsColumn = useCallback(
        (key: string) => {
            const gtins = csvData.data
                .map((row) => row[key])
                .filter(isStringGuard)
                .map((_) => _.trim())
                .filter((x) => x.length > 0)
                .filter((x) => !Number.isNaN(Number(x)));
            setGTINs(gtins);
        },
        [csvData.data]
    );

    const handleSubmit = useCallback(() => {
        return onSubmit(GTINs);
    }, [GTINs, onSubmit]);
    return (
        <Card
            title={t('SelectColumn')}
            actions={[
                <Button type={'primary'} onClick={handleSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <Select style={{ width: '100%' }} onChange={handleGtinsColumn}>
                {csvData.columns.map((column) => (
                    <Select.Option key={column.dataIndex} value={column.dataIndex}>
                        {column.title}
                    </Select.Option>
                ))}
            </Select>

            <Card title={'GTINs'} className={styles.gtins} bordered={false}>
                {GTINs.length === 0 ? (
                    <Empty />
                ) : (
                    <ul>
                        {GTINs.map((GTIN, index) => (
                            <li key={index}>{GTIN}</li>
                        ))}
                    </ul>
                )}
            </Card>
        </Card>
    );
};
