import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import { CognitoUser } from '@aws-amplify/auth';
import { TFunction } from 'i18next';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';

interface CompleteNewPasswordChallengeProps {
    t: TFunction;
    visible: boolean;
    cognitoUser: CognitoUser;
    onCancel: () => void;
    onOk: () => void;
}

const CompleteNewPasswordChallenge: FC<CompleteNewPasswordChallengeProps> = ({
    t,
    visible,
    onCancel,
    onOk,
    cognitoUser,
}) => {
    const [completePasswordChangeForm] = useForm();

    return (
        <Modal visible={visible} onOk={() => completePasswordChangeForm.submit()} onCancel={onCancel}>
            <Typography.Title>{t('Change Password')}</Typography.Title>
            <br />
            <Typography.Text>{t('Your account is new and never used so you need to change password')}</Typography.Text>
            <Form
                layout="vertical"
                form={completePasswordChangeForm}
                onFinish={(formValues) => {
                    cognitoUser.completeNewPasswordChallenge(formValues.password, [], {
                        onSuccess: (session) => {
                            message.success(t('Your password is now changed'));
                            onOk();
                        },
                        onFailure: (err) => {
                            message.error(err.message);
                            onCancel();
                        },
                    });
                }}
            >
                <Form.Item
                    label={t('New password')}
                    name={'password'}
                    rules={[{ required: true, message: t('{{field}} is required', { field: t('New Password') }) }]}
                >
                    <Input.Password autoFocus={true} placeholder={t('Please enter a new password')} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ResetPassword = (props: {
    visible: boolean;
    onCancel: () => void;
    onOk: () => void;
    t: TFunction;
}): React.ReactElement => {
    const [stage, setStage] = useState<'enterEmail' | 'enterCode'>('enterEmail');
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [code, setCode] = useState<string | undefined>(undefined);
    const [newPassword, setNewPassword] = useState<string | undefined>(undefined);

    const onNext = () => {
        if (stage === 'enterEmail') {
            if (email) {
                Auth.forgotPassword(email)
                    .then(() => {
                        message.success(props.t('Email sent with a verification code'));
                        setStage('enterCode');
                    })
                    .catch((err) => {
                        message.error(err.message);
                    });
            }
        }

        if (stage === 'enterCode') {
            if (!code) {
                message.error(props.t('Please enter the code sent to your email'));
                return;
            }

            if (!newPassword) {
                message.error(props.t('Please enter a new password'));
                return;
            }

            if (email && code && newPassword) {
                Auth.forgotPasswordSubmit(email, code, newPassword)
                    .then(() => {
                        message.success(props.t('Your password is now changed'));
                        props.onOk();
                    })
                    .catch((err) => {
                        message.error(err.message);
                    });
            }
        }
    };

    return (
        <Modal
            visible={props.visible}
            okButtonProps={{
                disabled: (stage === 'enterEmail' && !email) || (stage === 'enterCode' && !(newPassword && code)),
            }}
            onOk={onNext}
            onCancel={props.onCancel}
        >
            <Typography.Title>{props.t('Reset password')}</Typography.Title>
            <Form layout="vertical">
                {stage === 'enterEmail' && (
                    <Form.Item label={props.t('Username')} name="username">
                        <Input
                            onChange={(value) => setEmail(value.target.value)}
                            placeholder={props.t('Please enter your username')}
                        />
                    </Form.Item>
                )}

                {stage === 'enterCode' && (
                    <>
                        <Typography.Text strong>Please enter the confirmation code sent to your email</Typography.Text>
                        <br />
                        <br />
                        <Form.Item label={props.t('Verification code')} name="verificationCode">
                            <Input onChange={(value) => setCode(value.target.value)} />
                        </Form.Item>
                        <Form.Item label={props.t('New Password')} name="password">
                            <Input.Password onChange={(value) => setNewPassword(value.target.value)} />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
};

const SignInPage = () => {
    const [resetPassword, setResetPassword] = useQueryParam('reset_password', withDefault(BooleanParam, false));
    const [completePasswordChallenge, setCompletePasswordChallenge] = useState<boolean>(false);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const [signInForm] = useForm();

    const signIn = (form: { password: string; username: string }) => {
        Auth.signIn({ username: form.username, password: form.password })
            .then(async (cognitoUser: CognitoUser | any) => {
                if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setCompletePasswordChallenge(true);
                    setCognitoUser(cognitoUser as CognitoUser);
                    return;
                }
                const session = await Auth.currentSession();
                const token = await session.getAccessToken();
                const roles = token.payload['cognito:groups'] as string[];

                if (!(roles?.includes('label_user') || roles?.includes('admin'))) {
                    setTimeout(() => {
                        Auth.signOut();
                    }, 100);
                    message.error('You do not have the correct role to access the label service');
                } else {
                    history.replace(new URLSearchParams(location.search).get('from') || '/');
                }
            })
            .catch((err) => {
                console.log(err);
                message.error(err.message);
            });
    };

    return (
        <div style={{ width: '50%', margin: 'auto', marginTop: '15vh' }}>
            <ResetPassword
                t={t}
                visible={resetPassword}
                onCancel={() => setResetPassword(false)}
                onOk={() => setResetPassword(false)}
            />
            {cognitoUser && (
                <CompleteNewPasswordChallenge
                    t={t}
                    visible={completePasswordChallenge}
                    cognitoUser={cognitoUser}
                    onOk={() => {
                        setCompletePasswordChallenge(false);
                        signInForm.resetFields(['password']);
                    }}
                    onCancel={() => {
                        setCompletePasswordChallenge(false);
                    }}
                />
            )}

            <Row>
                <Col span={24}>
                    <Typography.Title>{t('Sign in')}</Typography.Title>
                </Col>

                <Col span={24}>
                    <Form onFinish={signIn} form={signInForm}>
                        <Form.Item label={t('Username')} name="username">
                            <Input />
                        </Form.Item>
                        <Form.Item label={t('Password')} name="password">
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                onClick={() => {
                                    setResetPassword(true);
                                }}
                            >
                                {t('Reset password')}
                            </Button>
                            <Button style={{ float: 'right' }} type="primary" htmlType="submit">
                                {t('Sign in')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default SignInPage;
