interface IEnv {
    REACT_APP_STAGE: string;
    REACT_APP_BUILD_VERSION: string;
    REACT_APP_COGNITO_USERPOOL_ID: string;
    REACT_APP_COGNITO_CLIENT_ID: string;
    REACT_APP_GATEWAY_ENDPOINT: string;
    PUBLIC_URL: string;
    NODE_ENV: 'development' | 'production' | 'test';
}

export const env = (process.env as unknown) as IEnv;
