import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useProducts } from '../api/product';
import { Card, Carousel, Col, Row, Tooltip, Typography } from 'antd';
import { GenericErrorComponent } from '../components/GenericErrorComponent';
import { ApolloError } from '@apollo/client';
import { AccreditationTypeCode, OriginInformation, ProductCategory } from '../models/models';
import { useTranslation } from 'react-i18next';
import { FileImageTwoTone } from '@ant-design/icons/lib';
import { TFunction } from 'i18next';
import { uniqueMarkings } from '../utils/product-utils';
import Barcode from 'react-barcode';

interface MarkingComponentProps {
    code: AccreditationTypeCode;
    productCategories: ProductCategory[];
    t: TFunction;
}

const MarkingComponent: FC<MarkingComponentProps> = ({ code, productCategories, t }) => {
    const messages = productCategories
        .find((category) => category.marking.id === code.info.id)
        ?.messages?.filter((message) => message.selected);

    return (
        <Row align={'middle'}>
            <Col lg={4} md={12} sm={12} xs={12}>
                {code.info.logo ? (
                    <img src={code.info.logo?.url} alt={code.info.name + ' logo'} style={{ width: '100px' }} />
                ) : (
                    <Tooltip title={t('This marking is missing a logo')} placement={'right'}>
                        <div style={{ width: '100px' }}>
                            <FileImageTwoTone style={{ fontSize: 100 }} />
                        </div>
                    </Tooltip>
                )}
            </Col>
            <Col lg={20} md={12} sm={12} xs={12}>
                <Typography.Text style={{ marginLeft: '20px' }}>
                    {code.info.shortDescription || code.info.name}
                </Typography.Text>
                {messages && (
                    <ul>
                        {messages
                            .filter((message) => message.selected)
                            .map((message, index) => (
                                <li key={index}>
                                    <Tooltip title={message.longDescription} placement={'right'}>
                                        <span>{message.shortDescription}</span>
                                    </Tooltip>
                                </li>
                            ))}
                    </ul>
                )}
            </Col>
        </Row>
    );
};

const hasOriginInformation = (originInformation?: OriginInformation) =>
    originInformation &&
    [
        originInformation.activityArea,
        originInformation.activityType,
        originInformation.originDeclaration,
        originInformation.originArea,
        originInformation.country,
    ].filter((prop) => prop !== null).length > 0;

export const ProductPage: FC = () => {
    const params = useParams<{ gtin: string }>();
    const { data, loading } = useProducts([params.gtin]);
    const { t } = useTranslation();

    if (data?.uniqueProductsByGTINs.length === 0) {
        return <GenericErrorComponent error={{ message: t('Product does not exist') } as ApolloError} />;
    }

    const product = data?.uniqueProductsByGTINs[0];

    const accreditationCodes: AccreditationTypeCode[] = uniqueMarkings(product);

    return (
        <>
            <Row gutter={[20, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Card loading={loading}>
                        <Row gutter={[20, 20]}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Row gutter={[20, 20]}>
                                    <Col span={24}>
                                        {product?.productImage &&
                                        product?.productImages.length > 0 &&
                                        product?.productImages[0].mimeType !== 'image/tiff' ? (
                                            <Carousel>
                                                {product?.productImages
                                                    ?.filter((image) => image.mimeType !== 'image/tiff')
                                                    .map((image, index) => {
                                                        return (
                                                            <div style={{ backgroundColor: 'grey' }} key={index}>
                                                                <img
                                                                    src={image.url}
                                                                    alt={product?.name}
                                                                    style={{ height: '400px', margin: 'auto' }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </Carousel>
                                        ) : (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '450px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography.Title level={2}>{t('Missing image')}</Typography.Title>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>{t('Brand name')}:</Col>
                                    <Col span={12}>{product?.brandName}</Col>
                                </Row>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>{t('Name')}:</Col>
                                    <Col span={12}>{product?.name}</Col>
                                </Row>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>{t('Package size')}:</Col>
                                    <Col span={12}>{product?.packageInformation.measurement.size}</Col>
                                </Row>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Row gutter={[20, 20]}>
                                    <Col span={24}>
                                        <Typography.Title level={4}>{t('Identity')}</Typography.Title>
                                    </Col>
                                </Row>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>GTIN:</Col>
                                    <Col span={12}>{product?.gtin}</Col>
                                </Row>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>EAN:</Col>
                                    <Col span={12}>
                                        <Barcode value={product?.gtin} />
                                    </Col>
                                </Row>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>{t('Information Provider')}:</Col>
                                    <Col span={12}>{product?.informationProvider.name}</Col>
                                </Row>
                                {hasOriginInformation(product?.originInformation) && (
                                    <Row gutter={[20, 20]}>
                                        <Col span={24}>
                                            <Typography.Title level={4}>{t('Origin')}</Typography.Title>
                                        </Col>
                                    </Row>
                                )}
                                {product?.originInformation?.country?.name && (
                                    <Row gutter={[20, 20]}>
                                        <Col span={12}>{t('Country of origin')}:</Col>
                                        <Col span={12}>{product?.originInformation?.country?.name}</Col>
                                    </Row>
                                )}
                                {product?.originInformation?.originArea && (
                                    <Row gutter={[20, 20]}>
                                        <Col span={12}>{t('Origin area')}:</Col>
                                        <Col span={12}>{product?.originInformation?.originArea}</Col>
                                    </Row>
                                )}
                                {product?.originInformation?.originDeclaration && (
                                    <Row gutter={[20, 20]}>
                                        <Col span={12}>{t('Origin declaration')}:</Col>
                                        <Col span={12}>{product?.originInformation?.originDeclaration}</Col>
                                    </Row>
                                )}
                                {product?.originInformation?.activityArea?.code && (
                                    <Row gutter={[20, 20]}>
                                        <Col span={12}>{t('Activity area')}:</Col>
                                        <Col span={12}>{product?.originInformation?.activityArea?.name}</Col>
                                    </Row>
                                )}
                                {product?.originInformation?.activityType?.code && (
                                    <Row gutter={[20, 20]}>
                                        <Col span={12}>{t('Activity type')}:</Col>
                                        <Col span={12}>{product?.originInformation?.activityType?.name}</Col>
                                    </Row>
                                )}
                                <Row gutter={[20, 20]}>
                                    <Col span={24}>
                                        <Typography.Title level={4}>{t('Product markings')}</Typography.Title>
                                    </Col>
                                </Row>
                                {accreditationCodes.map((code, index) => (
                                    <div key={index}>
                                        <Row gutter={[20, 20]}>
                                            <Col span={24}>
                                                {code.info && (
                                                    <MarkingComponent
                                                        code={code}
                                                        t={t}
                                                        productCategories={product?.productCategory || []}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
