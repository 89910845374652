import React, { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Marking, TypeCode } from '../models/models';
import { ArrowRightOutlined } from '@ant-design/icons/lib';
import { Link, useHistory } from 'react-router-dom';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { useMutation } from '@apollo/client';
import { MARKING_MUTATION } from '../api/marking';
import { UploadIcon } from './UploadIcon';
import { useTranslation } from 'react-i18next';
import { useAccreditationCodes } from '../api/typecodes';

export interface EditMarkingComponentProps {
    marking: Marking;
}

export const EditMarkingComponent: FC<EditMarkingComponentProps> = ({ marking }) => {
    const [editMarkingForm] = Form.useForm();
    const [markingSave] = useMutation<{ markingSave: Marking }>(MARKING_MUTATION);
    const { data } = useAccreditationCodes();
    const { t } = useTranslation();

    let sortedAccreditationCodes: TypeCode[] = [];
    if (data?.typeCodes) {
        sortedAccreditationCodes = [...data.typeCodes];
        sortedAccreditationCodes.sort((a, b) => a.code.localeCompare(b.code));
    }

    useEffect(() => {
        editMarkingForm.setFieldsValue({ id: marking.id });
        editMarkingForm.setFieldsValue({ organisationId: marking.organisationId });
        editMarkingForm.setFieldsValue({ name: marking.name });
        editMarkingForm.setFieldsValue({ shortDescription: marking.shortDescription });
        editMarkingForm.setFieldsValue({ longDescription: marking.longDescription });
        editMarkingForm.setFieldsValue({ gs1Code: marking.gs1Code });
    }, [marking, editMarkingForm]);

    const imageUrl = marking.logo?.url;

    const onSave = (_marking: Marking) => {
        markingSave({
            variables: {
                marking: {
                    ..._marking,
                    logo: marking.logo?.url ? { url: marking.logo?.url, mimeType: marking.logo?.mimeType } : null,
                },
            },
        })
            .then(() => {
                message.success(t('Marking saved successfully'));
                history.push(
                    buildPath(ROUTES.categories.path, {
                        organisationId: marking.organisationId + '',
                        markingId: marking.id as string,
                    })
                );
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    const history = useHistory();

    return (
        <Card title={t('Edit marking')}>
            <Form name="marking" form={editMarkingForm} onFinish={(marking) => onSave(marking)} layout={'vertical'}>
                <Form.Item name="id" hidden={true} />
                <Form.Item name="organisationId" hidden={true} />
                <Form.Item
                    label={t('Marking')}
                    name="name"
                    rules={[{ required: true, message: t('Please input your marking!') }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <UploadIcon markingId={marking.id} onError={(err) => message.error(err)} imageUrl={imageUrl} />
                </Form.Item>
                <Form.Item
                    label={t('Short description')}
                    name="shortDescription"
                    rules={[{ max: 80, message: t('Max {{number}} characters!', { number: 80 }) }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label={t('Long description')} name="longDescription">
                    <TextArea allowClear={true} rows={5} />
                </Form.Item>
                <Form.Item label={t('GS1 code')} name="gs1Code">
                    <Select
                        filterOption={(input, option) => {
                            if (option?.children) {
                                return (option?.children as string).toLowerCase().startsWith(input.toLowerCase());
                            }
                            return (option?.value).toLowerCase().startsWith(input.toLowerCase());
                        }}
                        optionFilterProp={'name'}
                        showSearch={true}
                    >
                        {sortedAccreditationCodes.map((typeCode) => (
                            <Select.Option value={typeCode.code} title={typeCode.description} key={typeCode.code}>
                                {typeCode.name || typeCode.code}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Row gutter={[20, 20]} justify={'end'}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Button htmlType="submit" type={'primary'} block={true}>
                            {t('Save and continue to categories')}
                        </Button>
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Link
                            to={buildPath(ROUTES.categories.path, {
                                organisationId: marking.organisationId + '',
                                markingId: marking.id as string,
                            })}
                        >
                            <Button icon={<ArrowRightOutlined />} block={true}>
                                {t('Continue to categories')}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
