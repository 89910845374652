import React, { FC, useState } from 'react';
import { useGtinsWithMissingCode, useProducts } from '../api/product';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { Marking, Product } from '../models/models';
import { useHistory } from 'react-router-dom';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { useTranslation } from 'react-i18next';
import { DeleteTwoTone, WarningTwoTone } from '@ant-design/icons/lib';
import * as swedishLocale from 'antd/es/locale/sv_SE';
import * as englishLocale from 'antd/es/locale/en_GB';
import { Key, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';

interface ProductsTableComponentProps {
    gtins: string[];
    marking: Marking;
    removeFromList: (product: Product) => void;
}

export const ProductsTable: FC<ProductsTableComponentProps> = ({ gtins, marking, removeFromList }) => {
    const [requestedGtins, setRequestedGtins] = useState<string[]>(gtins.slice(0, 10));
    const [pagination, setPagination] = useState<PaginationProps>({
        total: gtins.length,
        pageSize: 10,
        current: 1,
        pageSizeOptions: ['5', '10', '20'],
    });

    const { data, loading } = useProducts(requestedGtins);
    const { data: missingGtins, loading: missingGtinsLoading } = useGtinsWithMissingCode(gtins, marking.gs1Code);
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const onTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<Product> | SorterResult<Product>[],
        extra: TableCurrentDataSource<Product>
    ): void => {
        if (pagination.current && pagination.pageSize) {
            const start = pagination.current === 1 ? 0 : (pagination.current - 1) * pagination.pageSize;
            const end = pagination.current === 1 ? pagination.pageSize : pagination.current * pagination.pageSize;

            if (
                filters.markedInGS1 &&
                missingGtins?.gtinsWithMissingGs1Code &&
                missingGtins.gtinsWithMissingGs1Code.length > 0
            ) {
                setRequestedGtins(missingGtins.gtinsWithMissingGs1Code.slice(start, end));
                pagination.total = missingGtins.gtinsWithMissingGs1Code.length;
            } else {
                setRequestedGtins(gtins.slice(start, end));
                pagination.total = gtins.length;
            }
        }

        setPagination(pagination);
    };

    const columns = [
        {
            title: '',
            dataIndex: 'image',
            key: 'image',
            render: (_: any, product: Product) => {
                if (product.productImage) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={product.productImage.url} alt={product.name} height={50} />
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                height: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span>{t('Missing image')}</span>
                        </div>
                    );
                }
            },
        },
        { title: 'GTIN', dataIndex: 'gtin', key: 'gtin' },
        { title: t('Name'), dataIndex: 'name', key: 'name' },
        { title: t('Brand name'), dataIndex: 'brandName', key: 'brandName', responsive: ['lg'] as Breakpoint[] },
        {
            title: t('Information Provider'),
            dataIndex: 'informationProvider',
            key: 'informationProvider',
            responsive: ['lg'] as Breakpoint[],
            render: (_: any, product: Product) => {
                return <span>{product.informationProvider.name}</span>;
            },
        },
        {
            title: t('Info'),
            dataIndex: 'markedInGS1',
            key: 'markedInGS1',
            responsive: ['lg'] as Breakpoint[],
            filters: [
                {
                    text: t('Not tagged in Validoo'),
                    value: true,
                },
            ],
            onFilter: (value: any, product: Product) => {
                if (value) {
                    return (
                        product.markings?.accreditations.accreditationCodes
                            .filter((codes) => codes.info !== null)
                            .filter((code) => code.info.id === marking.id).length === 0
                    );
                }
                return true;
            },
            render: (_: any, product: Product) => {
                const missingGs1Marking =
                    product.markings?.accreditations.accreditationCodes
                        .filter((codes) => codes.info !== null)
                        .filter((code) => code.info.id === marking.id).length === 0;

                const currentMarking = product.markings?.accreditations.providedAccreditationCodes.find(
                    (codes) => codes.info.id === marking.id
                );

                if (missingGs1Marking) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip
                                title={t('This product is not tagged as {{marking}} in the GS1 data from Validoo', {
                                    marking: currentMarking?.info.name,
                                })}
                            >
                                <WarningTwoTone twoToneColor={'#FFCC00'} style={{ fontSize: 32 }} />
                            </Tooltip>
                        </div>
                    );
                }
                return '';
            },
        },
        {
            title: t('Actions'),
            key: 'actions',
            render: (_: any, product: Product) => (
                <Popconfirm
                    placement={'left'}
                    title={t('Are you sure you want to remove this product from this Category?')}
                    onConfirm={(event) => {
                        event?.stopPropagation();
                        removeFromList(product);
                    }}
                    onCancel={(event) => {
                        event?.stopPropagation();
                    }}
                    okText={t('Yes')}
                    cancelText={t('No')}
                >
                    <Button
                        danger
                        icon={<DeleteTwoTone twoToneColor={'red'} />}
                        type={'dashed'}
                        shape={'round'}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Popconfirm>
            ),
        },
    ];

    return (
        <Table
            dataSource={data?.uniqueProductsByGTINs}
            pagination={pagination}
            columns={columns}
            locale={i18n.language === 'sv' ? swedishLocale.default.Table : englishLocale.default.Table}
            rowClassName={'table-row'}
            rowKey={'id'}
            onChange={onTableChange}
            loading={loading || missingGtinsLoading}
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(
                            buildPath(ROUTES.product.path, {
                                gtin: record.gtin,
                            })
                        );
                    },
                };
            }}
        />
    );
};
