import { Breadcrumb, Button, Card, Col, message, Row, Table, Tag } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { ORGANISATION_MUTATION, useAllOrganisations } from '../api/organisation';
import { Organisation } from '../models/models';
import { HomeOutlined, LinkOutlined } from '@ant-design/icons/lib';
import { Link, useHistory } from 'react-router-dom';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
import { RegisterOrganisationModal } from '../components/RegisterOrganisationModal';
import { useMutation } from '@apollo/client';

export const OrganisationsPage: FC = () => {
    const { data, loading } = useAllOrganisations();

    const history = useHistory();
    const { t } = useTranslation();
    const [roles, setRoles] = useState<string[]>([]);
    const [showOrganisationModal, setShowOrganisationModal] = useState<boolean>(false);
    const [organisationSave] = useMutation<{ organisationSave: Organisation }>(ORGANISATION_MUTATION);

    useEffect(() => {
        const getUserRoles = async (): Promise<string[]> => {
            const session = await Auth.currentSession();
            const token = await session.getAccessToken();
            return token.payload['cognito:groups'] as string[];
        };
        getUserRoles().then((roles) => {
            setRoles(roles);
        });
    }, []);

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Rule development'),
            dataIndex: 'ruleDevelopment',
            key: 'ruleDevelopment',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: t('Ownership'),
            dataIndex: 'ownership',
            key: 'ownership',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: t('Markings'),
            key: 'markings',
            dataIndex: 'markings',
            responsive: ['lg'] as Breakpoint[],
            render: (_: any, organisation: Organisation) => (
                <>
                    {organisation.markings.map((marking) => {
                        return (
                            <Tag color="blue" key={marking.id}>
                                {marking.name.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: t('Actions'),
            key: 'actions',
            render: (_: any, organisation: Organisation) => (
                <>
                    <a href={organisation.website} target="_blank" rel="external noopener noreferrer">
                        <LinkOutlined /> {t('Open website')}
                    </a>
                </>
            ),
        },
    ];

    return (
        <>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined /> {t('Home')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t('Organisations')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Card title={t('Organisations')}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            rowKey={'id'}
                            rowClassName={'table-row'}
                            dataSource={data?.organisations}
                            loading={loading}
                            pagination={{
                                defaultPageSize: 50,
                            }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: () => {
                                        history.push(
                                            buildPath(ROUTES.organisation.path, { organisationId: record.id as string })
                                        );
                                    },
                                };
                            }}
                        />
                    </Col>
                </Row>
                {roles.includes('admin') && (
                    <Row gutter={[20, 20]} justify={'end'}>
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <Button type={'primary'} block onClick={() => setShowOrganisationModal(true)}>
                                {t('Add a new organisation')}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Card>

            <RegisterOrganisationModal
                showModal={showOrganisationModal}
                onSubmit={(organisation: Organisation) => {
                    organisationSave({
                        variables: { organisation: organisation },
                        refetchQueries: ['organisations'],
                    })
                        .then(() => {
                            message.success('Organisation saved successfully');
                        })
                        .catch((err) => {
                            message.error(err.message);
                        })
                        .finally(() => {
                            setShowOrganisationModal(false);
                        });
                }}
                onCancel={() => setShowOrganisationModal(false)}
            />
        </>
    );
};
