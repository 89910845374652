import React, { FC, useState } from 'react';
import { Breadcrumb, Button, Card, Col, message, Modal, Popconfirm, Row, Table } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useOrganisation } from '../api/organisation';
import { GenericErrorComponent } from '../components/GenericErrorComponent';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { Marking } from '../models/models';
import { RegisterMarkingModal } from '../components/RegisterMarkingModal';
import { ApolloProvider, useMutation } from '@apollo/client';
import { MARKING_DELETE_MUTATION, MARKING_MUTATION } from '../api/marking';
import { DeleteTwoTone, HomeOutlined } from '@ant-design/icons/lib';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { useTranslation } from 'react-i18next';
import { UploadIcon } from '../components/UploadIcon';
import { client } from '../client';

export const MarkingsPage: FC = () => {
    const params = useParams<{ organisationId: string }>();
    const history = useHistory();
    const [showRegisterMarkingModal, setShowRegisterMarkingModal] = useState<boolean>(false);
    const { data, error, loading } = useOrganisation(params.organisationId);
    const [markingSave] = useMutation<{ markingSave: Marking }>(MARKING_MUTATION);
    const [deleteMarking] = useMutation<{ markingDelete: Marking }>(MARKING_DELETE_MUTATION);
    const { t } = useTranslation();

    if (error) {
        return <GenericErrorComponent error={error}></GenericErrorComponent>;
    }

    const confirmDelete = (marking: Marking) => {
        deleteMarking({
            variables: { id: marking.id },
            refetchQueries: ['organisation'],
        })
            .then(() => {
                message.success(t('Marking deleted successfully'));
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: t('Logo'),
            dataIndex: 'logo',
            key: 'logo',
            render: (_: any, marking: Marking) => (
                <img width={60} src={marking.logo?.url} alt={`${marking.name} logo`} />
            ),
        },
        {
            title: t('Short description'),
            dataIndex: 'shortDescription',
            key: 'shortDescription',
        },
        {
            title: t('Long description'),
            dataIndex: 'longDescription',
            key: 'longDescription',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: t('GS1 code'),
            dataIndex: 'gs1Code',
            key: 'gs1Code',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: t('Number of categories'),
            key: 'categories',
            dataIndex: 'categories',
            responsive: ['lg'] as Breakpoint[],
            render: (_: any, marking: Marking) => <span>{marking.categories.length}</span>,
        },
        {
            title: t('Actions'),
            key: 'actions',
            render: (_: any, marking: Marking) => (
                <Popconfirm
                    placement={'left'}
                    title={t('Are you sure to delete this marking?')}
                    onConfirm={(event) => {
                        event?.stopPropagation();
                        confirmDelete(marking);
                    }}
                    onCancel={(event) => {
                        event?.stopPropagation();
                    }}
                    okText={t('Yes')}
                    cancelText={t('No')}
                >
                    <Button
                        danger
                        icon={<DeleteTwoTone twoToneColor={'red'} />}
                        type={'dashed'}
                        shape={'round'}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Popconfirm>
            ),
        },
    ];

    return (
        <div>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined /> {t('Home')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations'}>{t('Organisations')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations/' + data?.organisation.id}>{data?.organisation.name}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t('Markings')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Card title={t('Markings')}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            rowKey={'id'}
                            rowClassName={'table-row'}
                            dataSource={data?.organisation.markings}
                            loading={loading}
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        history.push(
                                            buildPath(ROUTES.marking.path, {
                                                organisationId: params.organisationId,
                                                markingId: record.id as string,
                                            })
                                        );
                                    },
                                };
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[20, 20]} justify={'end'}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Button type={'primary'} onClick={() => setShowRegisterMarkingModal(true)} block={true}>
                            {t('Add new marking')}
                        </Button>
                    </Col>
                </Row>
            </Card>

            <RegisterMarkingModal
                showModal={showRegisterMarkingModal}
                onSubmit={(marking) => {
                    marking.organisationId = Number(data?.organisation.id);
                    markingSave({
                        variables: { marking: marking },
                        refetchQueries: ['organisation'],
                    })
                        .then((markingResult) => {
                            Modal.success({
                                title: t('Do you wish to add logo?'),
                                cancelText: t('No'),
                                okCancel: true,
                                okText: t('Yes'),
                                content: (
                                    <ApolloProvider client={client}>
                                        <div style={{ display: 'flex', marginTop: '30px' }}>
                                            <div style={{ margin: 'auto' }}>
                                                <UploadIcon
                                                    markingId={markingResult.data?.markingSave.id}
                                                    onError={(err) => message.error(err)}
                                                    imageUrl={undefined}
                                                />
                                            </div>
                                        </div>
                                    </ApolloProvider>
                                ),
                            });
                            setShowRegisterMarkingModal(false);
                        })
                        .catch((err) => {
                            message.error(err.message);
                        })
                        .finally(() => {});
                }}
                onCancel={() => {
                    setShowRegisterMarkingModal(false);
                }}
            />
        </div>
    );
};
