import React, { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Organisation } from '../models/models';
import { Link, useHistory } from 'react-router-dom';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { useMutation } from '@apollo/client';
import { ORGANISATION_MUTATION } from '../api/organisation';
import { ArrowRightOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';

export interface EditOrganisationComponentProps {
    organisation: Organisation;
}

export const EditOrganisationComponent: FC<EditOrganisationComponentProps> = ({ organisation }) => {
    const [editOrganisationForm] = Form.useForm();
    const [organisationSave] = useMutation<{ organisationSave: Organisation }>(ORGANISATION_MUTATION);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        editOrganisationForm.setFieldsValue({ id: organisation.id });
        editOrganisationForm.setFieldsValue({ name: organisation.name });
        editOrganisationForm.setFieldsValue({ ruleDevelopment: organisation.ruleDevelopment });
        editOrganisationForm.setFieldsValue({ ownership: organisation.ownership });
        editOrganisationForm.setFieldsValue({ website: organisation.website });
    }, [organisation, editOrganisationForm]);

    const onFormSubmit = (organisation: Organisation) => {
        organisationSave({
            variables: { organisation: organisation },
        })
            .then(() => {
                message.success('Organisation saved successfully');
                history.push(buildPath(ROUTES.markings.path, { organisationId: organisation.id as string }));
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    return (
        <Card title={t('Edit organisation')}>
            <Form
                name="company"
                form={editOrganisationForm}
                onFinish={(organisation) => onFormSubmit(organisation)}
                layout={'vertical'}
            >
                <Form.Item name="id" hidden={true} />
                <Form.Item label={t('Organisation')} name="name">
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item label={t('Ownership')} name="ownership">
                    <TextArea allowClear={true} />
                </Form.Item>
                <Form.Item label={t('Rule development')} name="ruleDevelopment">
                    <TextArea allowClear={true} />
                </Form.Item>
                <Form.Item label={t('Website')} name="website">
                    <Input />
                </Form.Item>
                <Row gutter={[20, 20]} justify={'end'}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Button htmlType="submit" type={'primary'} block={true}>
                            {t('Save and continue to markings')}
                        </Button>
                    </Col>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Link to={buildPath(ROUTES.markings.path, { organisationId: organisation.id as string })}>
                            <Button icon={<ArrowRightOutlined />} block={true}>
                                {t('Continue to markings')}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
