import { uniqWith } from 'lodash';
import { AccreditationTypeCode, Product } from '../models/models';

const sameMarkingNameComparator = (marking1: AccreditationTypeCode, marking2: AccreditationTypeCode) => {
    return marking1 && marking2 && marking1.info && marking2.info
        ? marking1.info.name?.localeCompare(marking2.info.name) === 0
        : false;
};

export const uniqueMarkings = (product?: Product): AccreditationTypeCode[] => {
    let accreditationCodes: AccreditationTypeCode[] = [];

    if (product?.markings) {
        const transformedMarkings: AccreditationTypeCode[] = product.markings.accreditations.accreditationCodes.map(
            (markingsTypeCode) => {
                if (!markingsTypeCode.info) {
                    // return copy with only name and shortDescription and gs1Code if the info object is missing
                    // (that we are missing the markings in our sustainability database)
                    const accreditationTypeCode: AccreditationTypeCode = {
                        code: markingsTypeCode.code,
                        name: markingsTypeCode.name,
                        description: markingsTypeCode.description,
                        info: {
                            name: markingsTypeCode.name as string,
                            shortDescription: markingsTypeCode.description,
                            gs1Code: markingsTypeCode.code,
                            categories: [],
                        },
                    };
                    return accreditationTypeCode;
                }
                return markingsTypeCode;
            }
        );
        accreditationCodes = uniqWith(
            product.markings.accreditations.providedAccreditationCodes.concat(
                transformedMarkings.filter((codes) => codes.info !== null).filter((codes) => codes.info.name !== null)
            ),
            sameMarkingNameComparator
        );
    }

    return accreditationCodes;
};
