import { gql, useQuery } from '@apollo/client';
import { TypeCode } from '../models/models';

const TYPE_CODES = gql`
    query typeCodes($code: String!) {
        typeCodes(code: $code) {
            code
            name
            description
            usageInSweden
        }
    }
`;

export const useAccreditationCodes = () => {
    return useQuery<{ typeCodes: TypeCode[] }>(TYPE_CODES, { variables: { code: 'T3777' } });
};
