import React, { useState } from 'react';
import { Upload, Spin } from 'antd';
import { useMutation, defaultDataIdFromObject } from '@apollo/client';
import { SignedUrl, Marking, GraphQLTypenames } from '../models/models';
import { LOGO_SIGNED_URL_MUTATION, LOGO_UPLOADED_SUCCESS_MUTATION } from '../api/marking';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

interface IProps {
    markingId?: string;
    onError: (error: string) => void;
    imageUrl?: string;
}

export const UploadIcon = (props: IProps) => {
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [signedUrl] = useMutation<{ logoSignedUrl: SignedUrl }>(LOGO_SIGNED_URL_MUTATION);
    const [signedUrlSuccess] = useMutation<{ logoUploadedSuccess: Marking }>(LOGO_UPLOADED_SUCCESS_MUTATION);

    const uploadButton = (
        <div>
            {isLoadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Upload
            name="avatar"
            beforeUpload={async (event) => {
                try {
                    setIsLoadingImage(true);

                    const signedUrlRes = await signedUrl({
                        variables: { markingId: props.markingId, mimeType: event.type },
                    });

                    const url = signedUrlRes.data?.logoSignedUrl.url;

                    if (!url) {
                        throw new Error('Not able to get SignURL from API');
                    }

                    const buffer = await event.arrayBuffer();

                    await fetch(url, {
                        method: 'put',
                        body: buffer,
                        headers: new Headers({
                            'Content-Type': event.type,
                        }),
                    });

                    await signedUrlSuccess({
                        variables: { markingId: props.markingId, mimeType: event.type },
                        update(cache, res) {
                            cache.modify({
                                id: defaultDataIdFromObject({
                                    __typename: GraphQLTypenames.Marking,
                                    id: props.markingId,
                                }),
                                fields: {
                                    logo() {
                                        if (res.data?.logoUploadedSuccess.logo)
                                            return {
                                                ...res.data?.logoUploadedSuccess.logo,
                                                url: res.data?.logoUploadedSuccess.logo.url,
                                            };

                                        return null;
                                    },
                                },
                            });
                        },
                    });
                } catch (error) {
                    props.onError(error.toString());
                } finally {
                    setIsLoadingImage(false);
                }
            }}
            customRequest={() => {}}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
        >
            {isLoadingImage ? (
                <Spin></Spin>
            ) : (
                <>
                    {/* We append the timestamp to not use the browser cache */}
                    {props.imageUrl ? (
                        <img src={props.imageUrl} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                        uploadButton
                    )}
                </>
            )}
        </Upload>
    );
};
