import React, { FC, useCallback } from 'react';
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';

export interface IUploadFileStepProps {
    onFileLoaded: (file: RcFile) => void;
}

export const UploadFileStep: FC<IUploadFileStepProps> = (props) => {
    const { onFileLoaded } = props;
    const beforeUpload = useCallback(
        (file: RcFile): boolean => {
            if (file.type !== 'text/csv') {
                message.error(`${file.name} is not a .csv file`);
            }
            onFileLoaded(file);
            return false;
        },
        [onFileLoaded]
    );
    return (
        <>
            <Upload accept={'text/csv'} multiple={false} listType={'picture'} beforeUpload={beforeUpload}>
                <Button icon={<UploadOutlined />}>Upload .csv only</Button>
            </Upload>
        </>
    );
};
