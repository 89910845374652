import React, { FC, useCallback, useEffect, useState } from 'react';
import { CsvDataType, CsvFilePreview } from '../../../../components/CsvPreview';
import { RcFile } from 'antd/lib/upload/interface';
import { Button, message, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

export interface IParseFileStepProps {
    file: RcFile;
    onParsed: (data: { columns: { title: string; dataIndex: string }[]; data: Record<string, unknown>[] }) => void;
}

export const ParseFileStep: FC<IParseFileStepProps> = (props) => {
    const { file, onParsed } = props;

    const { t } = useTranslation();

    const [csvContent, setCsvContent] = useState<string>();
    const [csvData, setCsvData] = useState<{ columns: { title: string; dataIndex: string }[]; data: CsvDataType[] }>({
        columns: [],
        data: [],
    });

    useEffect(() => {
        file.text()
            .then(setCsvContent)
            .catch((err) => {
                message.error(err.message);
            });
    }, [file]);
    const handleSubmit = useCallback(() => {
        onParsed({
            columns: csvData.columns,
            data: csvData.data,
        });
    }, [onParsed, csvData]);
    if (!csvContent) {
        return <Skeleton active={true} loading={true} />;
    }
    return (
        <>
            <CsvFilePreview csvContent={csvContent} onChange={setCsvData} />
            <Button type={'primary'} disabled={csvData.data.length === 0} onClick={handleSubmit}>
                {t('SelectColumn')}
            </Button>
        </>
    );
};
