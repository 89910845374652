import React, { FC } from 'react';
import { Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Category } from '../models/models';
import { useTranslation } from 'react-i18next';

export interface RegisterCategoryModalProps {
    onSubmit: (category: Category) => void;
    onCancel: () => void;
    showModal: boolean;
}

export const RegisterCategoryModal: FC<RegisterCategoryModalProps> = ({ onSubmit, onCancel, showModal }) => {
    const [registerNewCategoryForm] = Form.useForm();
    const { t } = useTranslation();

    return (
        <Modal
            title={t('Register category')}
            visible={showModal}
            okText={t('Save')}
            cancelText={t('Close')}
            onOk={() => registerNewCategoryForm.submit()}
            maskClosable={false}
            keyboard={false}
            closable={false}
            onCancel={(event) => {
                registerNewCategoryForm.resetFields();
                onCancel();
            }}
        >
            <Form
                name="category"
                onFinish={(values) => {
                    onSubmit(values);
                    registerNewCategoryForm.resetFields();
                }}
                layout={'vertical'}
                form={registerNewCategoryForm}
            >
                <Form.Item
                    label={t('Name')}
                    name="name"
                    rules={[{ required: true, message: t('Please input your category name!') }]}
                >
                    <Input autoFocus={true} />
                </Form.Item>
                <Form.Item
                    label={t('Why is the marking important?')}
                    name="description"
                    rules={[{ max: 500, message: t('Max {{number}} characters!', { number: 500 }) }]}
                >
                    <TextArea allowClear={true} rows={6} />
                </Form.Item>

                <Form.Item
                    label={t('Brief description of rules')}
                    name="rule"
                    rules={[{ max: 750, message: t('Max {{number}} characters!', { number: 750 }) }]}
                >
                    <TextArea allowClear={true} rows={6} />
                </Form.Item>

                <Form.Item name="agendasSelected" label={t('Sustainability agendas')}>
                    <Checkbox.Group>
                        <Row>
                            <Col span={24}>
                                <Checkbox value="BIODIVERSITY_AND_ECOSYSTEMS">
                                    {t('Biodiversity and ecosystems')}
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="CLIMATE_AND_AIR">{t('Climate and air')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="SOIL_FERTILITY_AND_EROSION">
                                    {t('Soil fertility and erosion')}
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="WATER">{t('Water')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="CHEMICALS_AND_PESTICIDES">{t('Chemicals and pesticides')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="EUTROPHICATION">{t('Eutrophication')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="ANIMAL_WELFARE">{t('Animal welfare')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="WORKING_CONDITIONS">{t('Working conditions')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="LOCAL_POPULATIONS">{t('Local populations')}</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Checkbox value="LEGALTY_TRACEABILITY">{t('Legalty traceability')}</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};
