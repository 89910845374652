import React, { FC, useState } from 'react';
import { Breadcrumb, Button, Card, Col, message, Popconfirm, Row, Table, Tooltip } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useOrganisation } from '../api/organisation';
import { useMarking } from '../api/marking';
import { GenericErrorComponent } from '../components/GenericErrorComponent';
import { ApolloError, useMutation } from '@apollo/client';
import { buildPath } from '../utils/build-path';
import { ROUTES } from '../App';
import { Category } from '../models/models';
import { RegisterCategoryModal } from '../components/RegisterCategoryModal';
import { CATEGORY_DELETE_MUTATION, CATEGORY_MUTATION } from '../api/category';
import { DeleteTwoTone, HomeOutlined, InfoCircleTwoTone } from '@ant-design/icons/lib';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { useTranslation } from 'react-i18next';

export const CategoriesPage: FC = () => {
    const params = useParams<{ organisationId: string; markingId: string }>();
    const { data: organisationData, error: organisationError } = useOrganisation(params.organisationId);
    const { data: markingData, error: markingError, loading } = useMarking(params.markingId);
    const [saveCategory] = useMutation<{ categorySave: Category }>(CATEGORY_MUTATION);
    const [deleteCategory] = useMutation<{ categoryDelete: void }>(CATEGORY_DELETE_MUTATION);
    const { t } = useTranslation();

    const history = useHistory();

    const [showRegisterCategoryModal, setShowRegisterCategoryModal] = useState<boolean>(false);

    if (organisationError || markingError) {
        return <GenericErrorComponent error={(organisationError || markingError) as ApolloError} />;
    }

    const confirmDelete = (category: Category) => {
        deleteCategory({
            variables: { id: category.id },
            refetchQueries: ['marking'],
        })
            .then(() => {
                message.success(t('Category deleted successfully'));
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Short description'),
            dataIndex: 'description',
            key: 'description',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: t('Brief description of rules'),
            dataIndex: 'rule',
            key: 'rule',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: (
                <span>
                    {t('Number of messages')}
                    <Tooltip title={t('Visible in product views')}>
                        <InfoCircleTwoTone twoToneColor={'lightGrey'} style={{ marginLeft: 5 }} />
                    </Tooltip>
                </span>
            ),
            key: 'messages',
            dataIndex: 'messages',
            responsive: ['lg'] as Breakpoint[],
            render: (_: any, category: Category) => (
                <span>{category.messages.filter((message) => message.selected).length}</span>
            ),
        },
        {
            title: t('Actions'),
            key: 'actions',
            render: (_: any, category: Category) => (
                <Popconfirm
                    placement={'left'}
                    title={t('Are you sure to delete this category?')}
                    onConfirm={(event) => {
                        event?.stopPropagation();
                        confirmDelete(category);
                    }}
                    onCancel={(event) => {
                        event?.stopPropagation();
                    }}
                    okText={t('Yes')}
                    cancelText={t('No')}
                >
                    <Button
                        danger
                        icon={<DeleteTwoTone twoToneColor={'red'} />}
                        type={'dashed'}
                        shape={'round'}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Popconfirm>
            ),
        },
    ];

    return (
        <div>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined /> {t('Home')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/organisations">{t('Organisations')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations/' + organisationData?.organisation.id}>
                                {organisationData?.organisation.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations/' + organisationData?.organisation.id + '/markings'}>
                                {t('Markings')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={buildPath(ROUTES.marking.path, {
                                    organisationId: organisationData?.organisation.id as string,
                                    markingId: markingData?.marking.id as string,
                                })}
                            >
                                {markingData?.marking.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{t('Categories')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Card title={t('Categories')}>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <Table
                            columns={columns}
                            rowKey={'id'}
                            rowClassName={'table-row'}
                            dataSource={markingData?.marking.categories}
                            loading={loading}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: () => {
                                        history.push(
                                            buildPath(ROUTES.category.path, {
                                                organisationId: params.organisationId,
                                                markingId: params.markingId,
                                                categoryId: record.id as string,
                                            })
                                        );
                                    },
                                };
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[20, 20]} justify={'end'}>
                    <Col lg={6} md={24} sm={24} xs={24}>
                        <Button type={'primary'} onClick={() => setShowRegisterCategoryModal(true)} block={true}>
                            {t('Add new category')}
                        </Button>
                    </Col>
                </Row>
            </Card>
            <RegisterCategoryModal
                onSubmit={(category) => {
                    category.markingId = Number(markingData?.marking.id);
                    saveCategory({
                        variables: { category: category },
                        refetchQueries: ['marking'],
                    })
                        .then(() => {
                            message.success(t('Category saved successfully'));
                        })
                        .catch((err) => {
                            message.error(err.message);
                        })
                        .finally(() => {
                            setShowRegisterCategoryModal(false);
                        });
                }}
                onCancel={() => {
                    setShowRegisterCategoryModal(false);
                }}
                showModal={showRegisterCategoryModal}
            />
        </div>
    );
};
