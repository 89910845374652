import { Breadcrumb, Col, Row } from 'antd';
import { EditOrganisationComponent } from '../components/EditOrganisationComponent';
import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useOrganisation } from '../api/organisation';
import { GenericErrorComponent } from '../components/GenericErrorComponent';
import { HomeOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';

export const OrganisationPage: FC = () => {
    const params = useParams<{ organisationId: string }>();
    const { data, error } = useOrganisation(params.organisationId);
    const { t } = useTranslation();

    if (error) {
        return <GenericErrorComponent error={error}></GenericErrorComponent>;
    }

    return (
        <>
            <Row gutter={[0, 20]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined /> {t('Home')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/organisations'}>{t('Organisations')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data?.organisation.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {data?.organisation && (
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <EditOrganisationComponent organisation={data.organisation} />
                    </Col>
                </Row>
            )}
        </>
    );
};
